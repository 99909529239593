import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPoll } from "@fortawesome/free-solid-svg-icons";

class standardTile extends Component {
  render() {
    return (
      <div className="tile tile-standard" style={{height:"90px"}}>
        <div className="tile-img">
          <FontAwesomeIcon icon={faPoll} />
        </div>
        <div>
          <div className="tile-header">{this.props.header}</div>
          <div className="tile-content">{this.props.content}</div>
        </div>
      </div>
    );
  }
}

export default standardTile;
