import { UserManager } from "oidc-client";
import oidcSettings from "./../configuration/oidcSettings";

export default class AuthService {
  async initialize(signInCallback) {
    let signIn2 = () => this.signIn();

    this.userManager = new UserManager(oidcSettings);
    this.userManager
      .signinRedirectCallback()
      .then(function () {
        signInCallback();
      })
      .catch(function (e) {
        signIn2();
      });
  }


  signIn() {
    this.userManager.signinRedirect();
  }

  signOut() {
    this.userManager.signoutRedirect();
  }

  async getUserProfile() {
    var user = await this.userManager.getUser();
    if (user != null) return user.profile;
    return null;
  }

  async getAccessToken() {
    var result = null;
    await this.userManager.getUser().then((user) => {
      if (user != null) result = user.access_token;
    });
    return result;
  }

  async isLoggedIn() {
    var user = await this.userManager.getUser();
    return user != null && user.access_token != null;
  }
}
