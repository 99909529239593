import React, { Component } from "react";
import PageHeader from "./components/pageHeader";
import "./css/common.css";
import QuestionCategoryStatistics from "./components/questionCategoryStatistics";
import SummarySection from "./components/summarySection";
import FiltersSection from "./components/filtersSection";
import AvarageCategoryRateSection from "./components/avarageCategoryRateSection";
import AuthService from "./services/authService.js";
import ApiService from "./services/apiService.js";
import FilterViewSection from "./components/filterViews/filterViewsSection";

class App extends Component {
  constructor() {
    super();
    this.authService = new AuthService();
    this.apiService = new ApiService(this.authService);
  }

  componentDidMount() {
    this.authService.initialize(() => this.signedInCallback());
  }

  state = {
    surveyID: "",
    filters: [],
    compareFilters: [],
    summary: [],
    initialCategories: [],
    categories: [],
    compareCategories: [],
    filterViews: [],
    compareFilterViews: [],
    surveys: [],
    userProfile: {},
    signatures: [],
    compareEnabled: false,
    startFilterDate: "",
    endFilterDate: "",
    startCompareFilterDate: "",
    endCompareFilterDate: "",
    showInPercent: false,
    data: [],
  };

  render() {
    return (
      <div className="App">
        <PageHeader
          authService={this.authService}
          userProfile={this.state.userProfile}
          surveys={this.state.surveys}
          surveyID={this.state.surveyID}
          onSurveyChange={this.handleSurveySelect}
        />
        <div className="container">
          <SummarySection summary={this.state.summary} />
          <FilterViewSection
            filterViews={this.state.filterViews}
            applyFilterView={this.applyFilterView}
            removeFilterView={this.removeFilterView}
          />
          <FiltersSection
            filters={this.state.filters}
            compareFilters={this.state.compareFilters}
            compareEnabled={this.state.compareEnabled}
            startFilterDate={this.state.startFilterDate}
            endFilterDate={this.state.endFilterDate}
            startCompareFilterDate={this.state.startCompareFilterDate}
            endCompareFilterDate={this.state.endCompareFilterDate}
            onFilterChanged={this.handleFilterChanged}
            onCompareFilterChanged={this.handleCompareFilterChanged}
            onCompareEnabledChanged={this.handleCompareEnabledChanged}
            onFilterSaved={this.handleViewSaved}
            onChangeFilterStartDate={this.changeStartDate}
            onChangeFilterEndDate={this.changeEndDate}
            onChangeCompareFilterStartDate={this.changeCompareStartDate}
            onChangeCompareFilterEndDate={this.changeCompareEndDate}
            onResetFilters={this.onResetFilters}
          />
          <AvarageCategoryRateSection
            categories={this.state.categories.map((c) => ({
              categoryName: c.categoryName,
              categoryAverage: c.categoryAverage,
              percent: c.percent,
              ignoreAverageValue: c.ignoreAverageValue,
            }))}
            compareCategories={this.state.compareCategories.map((c) => ({
              categoryName: c.categoryName,
              categoryAverage: c.categoryAverage,
              percent: c.percent,
            }))}
            onshowInPercent={this.onshowInPercent}
            showInPercent={this.state.showInPercent}
          />
          {this.state.categories.map((c) => (
            <QuestionCategoryStatistics
              key={c.categoryName}
              compareEnabled={this.state.compareEnabled}
              onCompareEnabledChanged={this.handleCompareEnabledChanged}
              category={c}
              compareCategory={this.state.compareCategories.find(
                (cc) => cc.categoryName === c.categoryName
              )}
              showInPercent={this.state.showInPercent}
            />
          ))}

          {/* {this.state.signatures.length > 0 ? (
            <Carousel signatures={this.state.signatures} />
          ) : null} */}
        </div>
      </div>
    );
  }

  onResetFilters = async () => {
    console.log("reseted");
    await this.setState({ filters: [] });
    await this.setState({ startFilterDate: "" });
    await this.setState({ endFilterDate: "" });
    await this.setState({ startCompareFilterDate: "" });
    await this.setState({ endCompareFilterDate: "" });

    await this.setState({ compareFilters: [] });

    await this.fetchDataFromServer();
  };

  onshowInPercent = (bool) => {
    if (bool === true) {
      this.setState({ showInPercent: true });
    } else {
      this.setState({ showInPercent: false });
    }
  };

  filterDataForPeriod = (periodDate) => {
    this.apiService
      .getAggregateSurveyDataWithFilters(
        this.state.surveyID,
        periodDate,
        this.state.filters
      )
      .then((d) => {
        this.setState({ data: d });
        this.setState({ summary: d ? d.surveySummary.summaries : [] });
        this.setState({ initialCategories: d?  d.data.categories:[] });
        this.setState({ categories: d? d.data.categories : []});
      });
  };

  changeStartDate = async (date) => {
    if (!date) {
      return;
    }
    this.setState({ startFilterDate: date }, () => {
      const periodDate = {
        startFilterDate: this.state.startFilterDate,
        endFilterDate: this.state.endFilterDate,
      };
      this.filterDataForPeriod(periodDate);
    });
  };

  changeEndDate = async (date) => {
    if (!date) {
      return;
    }
    this.setState({ endFilterDate: date }, () => {
      const periodDate = {
        startFilterDate: this.state.startFilterDate,
        endFilterDate: this.state.endFilterDate,
      };

      this.filterDataForPeriod(periodDate);
    });
  };

  changeCompareStartDate = async (date) => {
    if (!date) {
      return;
    }
    this.setState({ startCompareFilterDate: date }, () => {
      const periodDate = {
        startFilterDate: this.state.startCompareFilterDate,
        endFilterDate: this.state.endCompareFilterDate,
      };
      this.filterDataForPeriod(periodDate);
    });
  };

  changeCompareEndDate = async (date) => {
    if (!date) {
      return;
    }
    this.setState({ endCompareFilterDate: date }, () => {
      const periodDate = {
        startFilterDate: this.state.startCompareFilterDate,
        endFilterDate: this.state.endCompareFilterDate,
      };

      this.filterDataForPeriod(periodDate);
    });
  };

  handleFilterChanged = async (filter, selected) => {
    const periodDate = {
      startFilterDate: this.state.startFilterDate,
      endFilterDate: this.state.endFilterDate,
    };

    const filters = [...this.state.filters];
    const index = filters.indexOf(filter);
    if (filter.isSingleSelect) {
      const newValues = selected.filter(
        (f) => !filters[index].selected.includes(f.value)
      );
      filters[index].selected = [
        newValues.length === 0 ? [] : newValues[0].value,
      ];
    } else {
      filters[index].selected = selected.map((f) => f.value);
    }
    this.setState({ filters });

    this.apiService
      .getAggregateSurveyDataWithFilters(
        this.state.surveyID,
        periodDate,
        this.state.filters
      )
      .then((d) => {
        this.setState({ data: d });
        this.setState({ summary: d.surveySummary.summaries });
        this.setState({ initialCategories: d.data.categories });
        this.setState({ categories: d.data.categories });
      });
  };

  handleCompareFilterChanged = async (filter, selected) => {
    const periodDate = {
      startFilterDate: this.state.startCompareFilterDate,
      endFilterDate: this.state.endCompareFilterDate,
    };
    const compareFilters = [...this.state.compareFilters];
    const index = compareFilters.indexOf(filter);
    if (filter.isSingleSelect) {
      const newValues = selected.filter(
        (f) => !compareFilters[index].selected.includes(f.value)
      );
      compareFilters[index].selected = [
        newValues.length === 0 ? [] : newValues[0].value,
      ];
    } else {
      compareFilters[index].selected = selected.map((f) => f.value);
    }
    this.setState({ compareFilters });
    this.apiService
      .getAggregateSurveyDataWithFilters(
        this.state.surveyID,
        periodDate,
        this.state.compareFilters
      )
      .then((d) => {
        this.setState({ compareCategories: d.data.categories || [] });
      });
  };

  handleCompareEnabledChanged = (checked) => {
    this.setState({
      compareEnabled: checked,
      compareCategories: checked ? this.state.initialCategories : [],
    });
  };

  async fetchDataFromServer() {
    const periodDate = {
      startFilterDate: this.state.startFilterDate,
      endFilterDate: this.state.endFilterDate,
    };
    console.log("state seurvey Id", this.state.surveyID);
    if (this.state.surveyID !== "") {
      // DATA
      this.apiService
        .getAggregateSurveyDataWithFilters(
          this.state.surveyID,
          periodDate,
          this.state.filters
        )
        .then((d) => {
          this.setState({ data: d });
          this.setState({ summary: d ? d.surveySummary.summaries : [] });
          this.setState({ initialCategories: d?  d.data.categories:[] });
          this.setState({ categories: d? d.data.categories : []});
        });
      // FILTERS
      this.apiService.getSurveyFilters(this.state.surveyID).then((f) => {
        this.setState({ filters: f.map((a) => ({ ...a })) });
        this.setState({ compareFilters: f.map((a) => ({ ...a })) });
      });
      // GET VIEWS
      this.apiService.getSurveyViews(this.state.surveyID).then((v) => {
        this.setState({ filterViews: v });
      });
    }
  }

  async signedInCallback() {
    var surveys = await this.apiService.getSurveyList();
    this.setState({ surveys: surveys });
    if (surveys.length > 0 && this.state.surveyID === "")
      this.setState({ surveyID: surveys[0].id });

    await this.fetchDataFromServer();
    this.authService.getUserProfile().then((profile) => {
      this.setState({ userProfile: profile });
    });
  }

  applyFilterView = (filters) => {
    const periodDate = {
      startFilterDate: this.state.startFilterDate,
      endFilterDate: this.state.endFilterDate,
    };

    this.setState({ filters: filters });
    this.apiService
      .getSurveyDataWithFilters(
        this.state.surveyID,
        filters,
        this.state.compareFilters,
        periodDate
      )
      .then((c) => {
        this.setState({ categories: c });
      });
  };

  applyCompareFilterView = (compareFilters) => {
    const periodDate = {
      startFilterDate: this.state.startFilterDate,
      endFilterDate: this.state.endFilterDate,
    };

    this.setState({ compareFilters: compareFilters });
    this.apiService
      .getSurveyDataWithFilters(
        this.state.surveyID,
        this.state.filters,
        compareFilters,
        periodDate
      )
      .then((c) => {
        this.setState({ compareCategories: c });
      });
  };

  handleViewSaved = async (viewName) => {
    var view = {
      viewName: viewName,
      viewFilters: this.state.filters,
      compareViewFilters: this.state.compareFilters,
    };
    var response = await this.apiService.postSurveyView(
      this.state.surveyID,
      view
    );
    this.apiService.getSurveyViews(this.state.surveyID).then((v) => {
      this.setState({ filterViews: v });
    });
  };

  handleSurveySelect = (event) => {
    this.setState({ surveyID: event.target.value }, () => {
      this.fetchDataFromServer();
    });
  };

  removeFilterView = async (viewId) => {
    await this.apiService.deleteSurveyView(this.state.surveyID, viewId);
    this.apiService.getSurveyViews(this.state.surveyID).then((v) => {
      this.setState({ filterViews: v });
    });
  };
}

export default App;
