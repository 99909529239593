import React, { Component } from "react";
import UserInfoPanel from "./userInfoPanel";
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "../css/pageHeader.css";

class pageHeader extends Component {
  render() {
    return (
      <header>
        <div className="header-area">
          <div className="header-top-area">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="logo">
                  <a href="/">
                    <img
                      src={`https://test.serwer.icpen.pl:4430/api/akredytacja/account/logo/${this.props.userProfile.customer} `}
                      alt="customer"
                    />
                  </a>
                </div>
                <div className="live-data-sign">
                  <label className="live-data-label">Live Data</label>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse float-right navbar-items"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a
                        className="nav-link contact-us"
                        target="_blank"
                        href="https://icpen.pl/kontakt/"
                      >
                        Skontaktuj się z nami
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <UserInfoPanel
                          authService={this.props.authService}
                          userProfile={this.props.userProfile}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <hr />
          <div className="header-bottom-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 text-center text-lg-left">
                  <select
                    className="form-control"
                    id="sel1"
                    onChange={this.props.onSurveyChange}
                    value={this.props.surveyID}
                  >
                    {this.props.surveys.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-7 d-none d-lg-block">
                  <div className="see-more">
                    <span>
                      Poznaj inne zastosowania systemu IC Pen w Służbie Zdrowia
                      na:{" "}
                    </span>
                    <a href="https://icpen.pl/sluzba-zdrowia/" target="_blank">
                      www.icpen.pl
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default pageHeader;
