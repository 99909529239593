import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePick.scss";

export const DatePick = (props) => {
  return (
    <>
      <DatePicker
        selected={props.startFilterDate}
        onChange={props.onChangeFilterStartDate}
        // selectsStart
        // startDate={startDate}
        // endDate={endDate}
        dateFormat="dd/MM/yyyy"
        // showMonthYearPicker
        placeholderText="od daty"
        className="date-picker"
      />
      <DatePicker
        selected={props.endFilterDate}
        onChange={props.onChangeFilterEndDate}
        // selectsEnd
        // startDate={startDate}
        // endDate={endDate}
        dateFormat="dd/MM/yyyy"
        // showMonthYearPicker
        placeholderText="do daty"
        className="date-picker"
      />
    </>
  );
};
