import React, { Component } from "react";
import VerticalBarChart from "./charts/verticalBarChart";
import Switch from "@material-ui/core/Switch";

class avarageCategoryRateSection extends Component {
  state = {
    graphType: "vertical-bar",
    // compareResults: this.props.state.compareResults
  };

  render() {
    return (
      <div>
        <div style={{ marginTop: "2em" }}>
          {" "}
          <span style={{ fontWeight: "700", fontSize: "14px" }}>
            Wyświetl wartości procentowe
          </span>
          <Switch
            checked={this.props.showInPercent}
            onChange={(evt) => this.props.onshowInPercent(evt.target.checked)}
            color="primary"
          />
        </div>
        <section className="tile">
          <div className="row question-statistics-padding">
            <div className="col-md-8">
              <label className="question-label align-middle">
                Średnia ocena kategorii
              </label>
            </div>
          </div>
          <hr />
          <div className="question-statistics-padding">
            {this.renderGraph()}
          </div>
        </section>
      </div>
    );
  }

  handleGraphTypeSelect = (event) => {
    this.setState({ graphType: event.target.value });
  };

  renderGraph() {
    return (
      <div className="chart-container-big">
        {this.state.graphType === "vertical-bar" && (
          <VerticalBarChart
            data={this.prepareData()}
            percentFormat={this.props.showInPercent}
          />
        )}
      </div>
    );
  }

  prepareData() {
    if (!this.props.categories.length) {
      return [];
    }

    const compareCategories = this.props.compareCategories || [];
    const categories = this.props.categories.filter(
      (c) => !c.ignoreAverageValue
    );

    return categories.map((item) => {
      const compare = compareCategories.find(
        (o) => o.categoryName === item.categoryName
      );
      if (this.props.showInPercent) {
        return {
          id: item.categoryName,
          base: item.percent,
          ...(compare && { compare: compare.percent }),
        };
      } else {
        return {
          id: item.categoryName,
          base: item.categoryAverage,
          ...(compare && { compare: compare.categoryAverage }),
        };
      }
    });
  }
}

export default avarageCategoryRateSection;
