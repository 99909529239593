import React, { Component } from "react";
import FilterView from "./filterView";
class filterViewSection extends Component {
  
  render() {
    return (
      <section>
        <div className="category-statistics-header">Widoki</div>
        <div className="row">
          {this.props.filterViews.map((v) => (
            <div key={v.viewName} className="col-lg-4 col-md-6">
              <FilterView
                view={v}
                applyFilterView={this.props.applyFilterView}
                removeFilterView={this.props.removeFilterView}
              />
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default filterViewSection;
