import React, { Component } from "react";
import MultiSelect from "react-multi-select-component";



class filterSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };


    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let strings = {
      selectSomeItems: "Brak filtra",
      allItemsAreSelected: "Zaznaczono wszystkie",
      selectAll: "Zaznacz wszystkie",
      search: "Wyszukaj",
    };

    return (
      <div
        className={
          (this.renderContainerStyleClasses(),
          this.props.compare ? "look how to add classes" : "")
        }
        style={{
          display: "flex",
          marginBottom: "1em",
          marginTop: "1em",
          marginRight: "2em",
        }}
      >
        <label className="form-check-label filter-label">
          {this.props.filter.label}
        </label>
        <br />
        <MultiSelect
          className="multi-select"
          options={this.prepareData().availableValues}
          value={this.prepareData().values}
          hasSelectAll={false}
          onChange={(e) => this.handleSelection(e)}
          overrideStrings={strings}
          labelledBy={"Select"}
          disableSearch
        />
      </div>
    );
  }

  renderContainerStyleClasses() {
    return this.state.width < 950
      ? "form-check filter-selector-group"
      : "form-check form-check-inline filter-selector-group";
  }
 
  handleSelection(e) {
    this.props.onFilterChanged(this.props.filter, e);
  }

  prepareData() {
    return {
      availableValues: this.props.filter.availableValues,
      values: this.props.filter.availableValues.filter((f) =>
        this.props.filter.selected.includes(f.value)
      ),
    };
  }
}

export default filterSelector;
