import React, { Component } from "react";
import { ResponsivePie } from "@nivo/pie";

class pieChart extends Component {
  render() {
    const tooltipFormat = (d) => (this.props.percentFormat ? `${d}%` : `${d}`);

    return (
      <ResponsivePie
        data={this.props.data}
        margin={{ top: 20, right: 40, bottom: 20, left: 40 }}
        startAngle={-13}
        innerRadius={0.55}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: "category10" }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltipFormat={tooltipFormat}

        // legends={[
        //   {
        //     anchor: "right",
        //     direction: "column",
        //     translateY: 0,
        //     itemWidth: 75,
        //     itemHeight: 25,
        //     itemTextColor: "#999",
        //     symbolSize: 18,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemTextColor: "#000",
        //         },
        //       },
        //     ],
        //   },
        // ]}
      />
    );
  }
}

export default pieChart;
