import React from "react";
import StandardTile from "./standardTile";

const summarySection = (props) => {
  return (
    <section>
      <div className="category-statistics-header">Podsumowanie</div>
      <div className="row">
        {props.summary.length ? props.summary.map((s) => (
          <div key={s.header} className="col-lg-3 col-md-6">
            <StandardTile header={s.header} content={s.content} />
          </div>
        )) : <div></div>}
      </div>
    </section>
  );
};

export default summarySection;
