import React, { Component } from "react";
import { ResponsiveBar } from "@nivo/bar";

class horizontalBarChart extends Component {
  render() {
    const tooltipFormat = (d) => (this.props.percentFormat ? `${d}%` : `${d}`);

    return (
      <ResponsiveBar
        data={this.props.data}
        indexBy="id"
        keys={["base","compare"]}
        margin={{ top: 0, right: 20, bottom: 50, left: 80 }}
        padding={0.3}
        layout="horizontal"
        colors={{ scheme: "category10" }}
        colorBy="index"
        groupMode="grouped"
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Liczba odpowiedzi",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltipFormat={tooltipFormat}

      />
    );
  }
}

export default horizontalBarChart;
