import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
class filterView extends Component {

  render() {
    return (
      <div className="tile tile-filter-view">
        <div className="d-flex">
          <div className="view-label">{this.props.view.viewName}</div>
          <div className="ml-auto filter-view-buttons">
            <button
              className="float-right btn-standard btn-remove"
              onClick={() =>
                this.props.removeFilterView(this.props.view.viewId)
              }
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button
              className="float-right btn-standard"
              onClick={() =>
                this.props.applyFilterView(this.props.view.viewFilters)
              }
            >
              Wczytaj
            </button>
            <button className="float-right btn-standard btn-excel">
              <FontAwesomeIcon className="btn-excel-icon" icon={faFileExcel} />
              Excel
            </button>
          </div>
        </div>
        {this.props.view.viewFilters.map((f) => (
          <span key={f.label} className="filter-view-group">
            <label className="filter-label">{f.label}: </label>
            {f.selected.length !== f.availableValues.length &&
              f.selected.map((v) => (
                <span key={v} className="filter-view-value">
                  {" "}
                  {this.getFilterLabel(f, v)},{" "}
                </span>
              ))}
            {f.selected.length === 0 && (
              <span className="filter-view-value"> brak filtra, </span>
            )}
            {f.selected.length === f.availableValues.length && (
              <span className="filter-view-value"> wszystkie </span>
            )}
          </span>
        ))}
      </div>
    );
  }

  getFilterLabel(filter, value) {
    return filter.availableValues.find((av) => av.value === value).label;
  }
}

export default filterView;
