import React, { Component } from "react";
import "../css/userInfoPanel.css";

class userInfoPanel extends Component {
  render() {
    return (
      <div className="dropdown">
        <div
          className="userInfoPanel"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="initialsCircle d-none d-lg-block">
            <span className="userNameInitials">{this.getNameInitials()}</span>
          </div>
          <div className="userName dropdown-toggle">{this.getUserName()}</div>
        </div>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            className="dropdown-item"
            target="_blank"
            href="http://docs.icpen.pl/"
          >
            Pomoc
          </a>
          <button
            className="dropdown-item"
            onClick={() => this.props.authService.signOut()}
          >
            Wyloguj się
          </button>
        </div>
      </div>
    );
  }

  getUserName() {
    var profile = this.props.userProfile;
    return profile.name || "";
  }

  getNameInitials() {
    return this.getUserName()
      .split(" ")
      .map((word) => word[0])
      .join("");
  }
}

export default userInfoPanel;
