import React, { Component } from "react";
import HorizontalBarChart from "./charts/horizontalBarChart";
import VerticalBarChart from "./charts/verticalBarChart";
import PieChart from "./charts/pieChart";
import ignoreIcon from "../assets/img/red.svg";
import ReactTooltip from "react-tooltip";
import Carousel from "../components/Carousel/Carousel";

class statisticsPanel extends Component {
  constructor(props) {
    super(props);
    if (props.question.graphType != null) {
      this.state = {
        graphType: props.question.graphType,
        compareEnabled: false,
      };
    } else {
      this.state = { graphType: "pie", compareEnabled: false };
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.compareEnabled !== state.compareEnabled) {
      return {
        ...state,
        graphType: props.compareEnabled ? "horizontal-bar" : "pie",
        compareEnabled: props.compareEnabled,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  render() {
    return (
    
        <section className="tile my-3 ">
          <div className="row question-statistics-padding ">
            <div className="col-lg-9">
              <label className="question-label align-middle">
                {this.props.question.questionContent}
              </label>
            </div>
            <div className="col-lg-3">
              {!this.props.question.images.length &&
              this.props.question.isCountInAverage === true ? (
                <div className="form-group m-0 w-100 float-right">
                  <select
                    className="form-control"
                    id="sel1"
                    onChange={this.handleGraphTypeSelect}
                    value={this.state.graphType}
                  >
                    {!this.props.compareEnabled && (
                      <option value="pie">Kołowy</option>
                    )}
                    <option value="horizontal-bar">Horyzontalny</option>
                    <option value="vertical-bar">Wertykalny</option>
                  </select>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <hr />

          <div className="question-statistics-padding">
            {this.props.question.isCountInAverage === false &&
            this.props.question.images.length ? (
              <div  >
                <Carousel signatures={this.props.question.images} />
                <img
                  src={ignoreIcon}
                  style={{ width: "15px" }}
                  alt="Pytanie ignorowane"
                  data-tip="Wyniki tego pytania nie wliczają się do średniej kategorii"
                />
              </div>
            ) : (
              <div >{this.renderGraph()}</div>
            )}
          </div>
        </section>
 
    );
  }

  handleGraphTypeSelect = (event) => {
    this.setState({ graphType: event.target.value });
  };

  renderGraph() {
    return (
      <div>
        <div className="chart-container">
          {this.state.graphType === "pie" && (
            <PieChart
              data={this.prepareDataForCircle()}
              percentFormat={this.props.showInPercent}
            />
          )}
          {this.state.graphType === "horizontal-bar" && (
            <HorizontalBarChart
              data={this.prepareData()}
              percentFormat={this.props.showInPercent}
            />
          )}
          {this.state.graphType === "vertical-bar" && (
            <VerticalBarChart
              data={this.prepareData()}
              percentFormat={this.props.showInPercent}
            />
          )}
          <ReactTooltip />
        </div>
        {this.props.question.isCountInAverage === false && (
          <div>
            <img
              src={ignoreIcon}
              style={{ width: "15px" }}
              alt="Pytanie ignorowane"
              data-tip="Wyniki tego pytania nie wliczają się do średniej kategorii"
            />
          </div>
        )}
      </div>
    );
  }

  prepareDataForCircle() {
    return this.props.question.answers.map((ans) =>
      this.props.showInPercent
        ? {
            id: ans.answerContent,
            label: ans.answerContent,
            value: (ans.percent * 100).toFixed(1),
          }
        : {
            id: ans.answerContent,
            label: ans.answerContent,
            value: ans.count,
          }
    );
  }

  prepareData() {
    if (!this.props.question.answers.length) {
      return [];
    }

    const compareAnswers =
      this.props.compareQuestion && this.props.compareQuestion.answers
        ? this.props.compareQuestion.answers
        : [];

    return this.props.question.answers.map((item) => {
      const compare = compareAnswers.find(
        (o) => o.answerContent === item.answerContent
      );
      if (this.props.showInPercent) {
        return {
          id: item.answerContent,
          base: (item.percent * 100).toFixed(1),
          ...(compare && { compare: (compare.percent * 100).toFixed(1) }),
        };
      } else {
        return {
          id: item.answerContent,
          base: item.count,
          ...(compare && { compare: compare.count }),
        };
      }
    });
  }
}

export default statisticsPanel;
