const baseUrl = "https://test.serwer.icpen.pl:4430/api/akredytacja/";
// const baseUrl = "https://produkty.icpen.pl/api/akredytacja/";

export default class ApiService {
  constructor(authService) {
    this.authService = authService;
  }

  async getSurveyList() {
    try {
      var accessToken = await this.authService.getAccessToken();
      var response = await fetch(`${baseUrl}survey`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
        }),
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getSurveySummaries(surveyId, periodDate, filters) {
    var queryString = this.buildQueryStringForFilters(filters);

    const date =
      periodDate.startFilterDate && periodDate.endFilterDate !== ""
        ? `&FromDate=${periodDate.startFilterDate.toLocaleDateString(
            "en-US"
          )}&ToDate=${periodDate.endFilterDate.toLocaleDateString("en-US")}`
        : "";
    try {
      var accessToken = await this.authService.getAccessToken();
      var response = await fetch(
        `${baseUrl}survey/${surveyId}/summaries${queryString}${date}`,

        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + accessToken,
          }),
        }
      );
      const aaa = response.json();
      return aaa;
    } catch (error) {
      console.log(error);
    }
  }

  async getSurveyFilters(surveyId) {
    try {
      var accessToken = await this.authService.getAccessToken();
      var response = await fetch(`${baseUrl}survey/${surveyId}/filters`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
        }),
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getSurveyData(surveyId, periodDate) {
    const date =
      periodDate.startFilterDate && periodDate.endFilterDate !== ""
        ? `?FromDate=${periodDate.startFilterDate.toLocaleDateString(
            "en-US"
          )}&ToDate=${periodDate.endFilterDate.toLocaleDateString("en-US")}`
        : "";
    try {
      var accessToken = await this.authService.getAccessToken();
      var response = await fetch(
        `${baseUrl}survey/${surveyId}/getdata${date}`,
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + accessToken,
          }),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getAggregateSurveyDataWithFilters(
    surveyId,
    periodDate,
    filters = [],
    compareFilters = []
  ) {
    try {
      var accessToken = await this.authService.getAccessToken();

      let params = new URLSearchParams({
        // ...(periodDate &&
        //   periodDate.startFilterDate &&
        //   periodDate.endFilterDate && {
        //     FromDate: periodDate.startFilterDate.toLocaleDateString("en-US"),
        //     ToDate: periodDate.endFilterDate.toLocaleDateString("en-US"),
        //   }),

        FromDate:
          periodDate.startFilterDate !== ""
            ? periodDate.startFilterDate.toLocaleDateString("en-US")
            : "",
        ToDate:
          periodDate.endFilterDate !== ""
            ? periodDate.endFilterDate.toLocaleDateString("en-US")
            : "",
      });

      params = this.appendFilterToParams(params, filters);

      const url = `${baseUrl}survey/${surveyId}/aggregate?${params}`;

      const options = {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
        }),
      };

      const response = await fetch(url, options);
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getSurveyDataWithFilters(
    surveyId,
    filters,
    compareFilters,
    periodDate
  ) {
    const date =
      periodDate &&
      periodDate.startFilterDate &&
      periodDate.endFilterDate !== ""
        ? `&FromDate=${periodDate.startFilterDate.toLocaleDateString(
            "en-US"
          )}&ToDate=${periodDate.endFilterDate.toLocaleDateString("en-US")}`
        : "";
    try {
      var accessToken = await this.authService.getAccessToken();
      var queryString = this.buildQueryStringForFilters(
        filters,
        compareFilters
      );
      var response = await fetch(
        `${baseUrl}survey/${surveyId}/getdata${queryString}${date}`,
        // `${baseUrl}survey/${surveyId}/getdata${queryString}`,

        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + accessToken,
          }),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getSurveyViews(surveyId) {
    try {
      var accessToken = await this.authService.getAccessToken();
      var response = await fetch(`${baseUrl}survey/${surveyId}/views`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
        }),
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async getImagesForCarousel(surveyId, periodDate, filters) {
    const date =
      periodDate.startFilterDate && periodDate.endFilterDate !== ""
        ? `&FromDate=${periodDate.startFilterDate.toLocaleDateString(
            "en-US"
          )}&ToDate=${periodDate.endFilterDate.toLocaleDateString("en-US")}`
        : "";
    var queryString = this.buildQueryStringForFilters(filters);

    try {
      const accessToken = await this.authService.getAccessToken();
      const response = await fetch(
        `${baseUrl}survey/${surveyId}/answers/open/images${queryString}${date}`,

        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + accessToken,
          }),
        }
      );
      return response.json();
    } catch (error) {
      console.log(error);
    }
  }

  async postSurveyView(surveyId, view) {
    try {
      var accessToken = await this.authService.getAccessToken();
      var response = await fetch(`${baseUrl}survey/${surveyId}/views`, {
        method: "post",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json;charset=UTF-8",
        }),
        body: JSON.stringify(view),
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async deleteSurveyView(surveyId, viewId) {
    try {
      var accessToken = await this.authService.getAccessToken();
      var response = await fetch(
        `${baseUrl}survey/${surveyId}/views/${viewId}`,
        {
          method: "delete",
          headers: new Headers({
            Authorization: "Bearer " + accessToken,
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  // HELPERS
  buildQueryStringForFilters(filters) {
    let query = "";
    filters.forEach((f) => {
      f.selected.forEach((s) => {
        query += "&" + f.filterName + "=" + s;
      });
    });
    return "?" + query.slice(1);
  }

  appendFilterToParams(params, filters) {
    filters.forEach((f) => {
      f.selected.forEach((selectedId) => {
        params.append(f.filterName, selectedId);
      });
    });

    return params;
  }
}
