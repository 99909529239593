import React, { Component } from "react";
import StatisticsPanel from "./statisticsPanel";
import StandardTile from "./standardTile";

class questionCategoryStastistics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section>
        <div className="category-statistics-header">
          Kategoria: {this.props.category.categoryName}
        </div>
        {!this.props.category.ignoreAverageValue && (
          <div className="row" style={{ justifyContent: "start" }}>
            <div className="col-lg-3 col-md-4">
              <StandardTile
                header="Średnia ocena kategorii:"
                content={this.props.category.categoryAverage}
              />
            </div>
            <div className="col-lg-3 col-md-4">
              <StandardTile
                header="Średnia procentowa ocena kategorii:"
                content={this.props.category.percent+'%'} 
              />
            </div>
          </div>
        )}

        <div className="row">
          {this.props.category.questions.map((q) => (
            <div key={q.questionContent} className={( this.props.category.questions[0].images.length === 0) ? "col-md-6" : "col-md-12"}>
              <StatisticsPanel
                showInPercent={this.props.showInPercent}
                question={q}
                compareQuestion={this.compareQuestions(q)}
                compareEnabled={this.props.compareEnabled}
                onCompareEnabledChanged={this.props.handleCompareEnabledChanged}
                categories={this.props.categories}
                compareCategories={this.props.compareCategories}
              />
            </div>
          ))}
        </div>
      </section>
    );
  }

  compareQuestions = (baseQuestion) => {
    if (!this.props.compareCategory || !this.props.compareCategory.questions) {
      return;
    }

    return this.props.compareCategory.questions.find(
      (cc) => cc.questionContent === baseQuestion.questionContent
    );
  };
}

export default questionCategoryStastistics;
