import React, { Component, Fragment } from "react";
import { ResponsiveBar } from "@nivo/bar";

class verticalBarChart extends Component {
  render() {
    const tooltipFormat = (d) => (this.props.percentFormat ? `${d}%` : `${d}`);

    return (
      <Fragment>
        <ResponsiveBar
          data={this.props.data}
          indexBy="id"
          keys={["base", "compare"]}
          margin={{ top: 20, right: 20, bottom: 50, left: 40 }}
          padding={0.3}
          layout="vertical"
          colors={{ scheme: "category10" }}
          colorBy="index"
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          groupMode="grouped"
          tooltipFormat={tooltipFormat}
        />
      </Fragment>
    );
  }
}

export default verticalBarChart;
