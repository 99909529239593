import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import FilterSelector from "./filterSelector";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { DatePick } from "./date-picker/DatePick";

class filtersSection extends Component {
  state = {
    excelDialogOpen: false,
    saveViewDialogOpen: false,
    saveCompareDialogOpen: false,
  };

  render() {
    return (
      <section>
        <div className="category-statistics-header">
          <div className="d-inline">Filtry</div>
          <button
            className="d-inline btn-standard btn-excel"
            onClick={() => this.handleUnlockOpen()}
          >
            <FontAwesomeIcon className="btn-excel-icon" icon={faFileExcel} />
            Excel
          </button>

          {this.props.compareEnabled ? (
            <button
              className="d-inline btn-standard"
              onClick={() => this.handleSaveCompareClickOpen()}
            >
              Zapisz jako nowe porownanie
            </button>
          ) : (
            <button
              className="d-inline btn-standard "
              onClick={() => this.handleSaveViewClickOpen()}
            >
              Zapisz jako nowy widok
            </button>
          )}

          <FormControl component="fieldset" className="float-right">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    checked={this.props.compareEnabled}
                    onChange={(evt) =>
                      this.props.onCompareEnabledChanged(evt.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Włącz porównywanie"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </div>

        {/* -------1----- */}
        <div
          className={
            this.props.compareEnabled ? "tile p-12 compare-block" : "tile p-12 "
          }
        >
          {this.props.compareEnabled && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "#698ed0",
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              >
                Zestaw bazowy
              </div>
              <div style={{ color: "#698ed0", fontSize: "0.8em" }}>
                Wybierz kryterium w zestawie bazowym, a nastepnie w
                porownawczym.
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {this.props.filters.map((f) => (
              <FilterSelector
                filter={f}
                key={f.label}
                onFilterChanged={this.props.onFilterChanged}
                startFilterDate={this.props.startFilterDate}
                endFilterDate={this.props.endFilterDate}
              />
            ))}
            <div
              style={{
                display: "flex",
                marginBottom: "1em",
                marginTop: "1em",
                marginRight: "1em",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "700", fontSize: "14px" }}>
                Wybierz okres dat{" "}
              </span>
              <DatePick
                onChangeFilterStartDate={this.props.onChangeFilterStartDate}
                onChangeFilterEndDate={this.props.onChangeFilterEndDate}
                startFilterDate={this.props.startFilterDate}
                endFilterDate={this.props.endFilterDate}
              />
            </div>
          </div>
        </div>

        {/* -------2----- */}
        {this.props.compareEnabled && (
          <div
            className="tile p-12"
            style={{
              border: "solid 3px rgb(105, 142, 208)",
              backgroundColor: "#f1f6ff",
              borderTop: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: "#698ed0",
                fontSize: "1.2em",
                fontWeight: "bold",
              }}
            >
              Zestaw porownawczy
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {this.props.compareFilters.map((f) => (
                <FilterSelector
                  filter={f}
                  key={f.label}
                  onFilterChanged={this.props.onCompareFilterChanged}
                />
              ))}
              <div
                style={{
                  display: "flex",
                  marginBottom: "1em",
                  marginTop: "1em",
                  marginRight: "1em",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  Wybierz okres dat{" "}
                </span>
                <DatePick
                  onChangeFilterStartDate={
                    this.props.onChangeCompareFilterStartDate
                  }
                  onChangeFilterEndDate={
                    this.props.onChangeCompareFilterEndDate
                  }
                  startFilterDate={this.props.startCompareFilterDate}
                  endFilterDate={this.props.endCompareFilterDate}
                />
              </div>
            </div>
          </div>
        )}
        <button
          className="d-inline btn-standard "
          onClick={this.props.onResetFilters}
        >
          Resetuj wszystkie filtry
        </button>
        <Dialog
          open={this.state.excelDialogOpen}
          onClose={() => this.handleUnlockClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Wersja demonstracyjna"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ta funkcjonalność nie jest dostępna w wersji demonstracyjnej.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleUnlockClose()}
              color="primary"
              autoFocus
            >
              Rozumiem
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.saveViewDialogOpen}
          onClose={() => this.handleSaveViewClose()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Zapisz nowy widok</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Zapisz obecnie ustawione filtry w formie widoku, aby mieć szybki
              dostęp do Twoich ulubionych filtrów.
            </DialogContentText>
            <TextField
              onChange={this.filterNameChanged}
              autoFocus
              margin="dense"
              id="name"
              label="Nazwa nowego widoku"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleSaveViewClose()} color="primary">
              Anuluj
            </Button>
            <Button onClick={() => this.handleSaveView()} color="primary">
              Zapisz nowy widok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.saveCompareDialogOpen}
          onClose={() => this.handleSaveCompareClose()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Zapisz nowe porownanie
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Zapisz obecnie ustawione filtry w formie porownania, aby mieć
              szybki dostęp do Twoich ulubionych filtrów.
            </DialogContentText>
            <TextField
              onChange={this.filterNameChanged}
              autoFocus
              margin="dense"
              id="name"
              label="Nazwa nowego porownania"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleSaveCompareClose()}
              color="primary"
            >
              Anuluj
            </Button>
            <Button onClick={() => this.handleSaveCompare()} color="primary">
              Zapisz nowe porownanie
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    );
  }

  filterNameChanged = (e) => {
    this.setState({ viewName: e.target.value });
  };

  handleUnlockOpen() {
    this.setState({
      excelDialogOpen: true,
    });
  }

  handleUnlockClose() {
    this.setState({ excelDialogOpen: false });
  }

  handleSaveViewClickOpen() {
    this.setState({
      saveViewDialogOpen: true,
    });
  }

  handleSaveCompareClickOpen() {
    this.setState({
      saveCompareDialogOpen: true,
    });
  }

  handleSaveViewClose() {
    this.setState({ saveViewDialogOpen: false });
  }

  handleSaveCompareClose() {
    this.setState({ saveCompareDialogOpen: false });
  }

  handleSaveView() {
    this.props.onFilterSaved(this.state.viewName);
    this.setState({ saveViewDialogOpen: false });
  }

  handleSaveCompare() {
    this.props.onFilterSaved(this.state.viewName);
    this.setState({ saveCompareDialogOpen: false });
  }
}

export default filtersSection;
