export default {
  authority: "https://test.serwer.icpen.pl:4430/Authorization/",
  // authority: "https://oauth.produkty.icpen.pl/",
  client_id: "survey_data_aggregator_client",
  redirect_uri: "https://test.serwer.icpen.pl:4430",
  response_type: "code",
  response_mode: "query",
  scope: "openid profile survey_aggregator_api",
  // post_logout_redirect_uri: "https://akredytacja.icpen.pl",
  post_logout_redirect_uri: "https://test.serwer.icpen.pl:4430",
};

// export default {
//   authority: "https://test.serwer.icpen.pl:4430/Authorization/",
//   // authority: "https://oauth.produkty.icpen.pl/",
//   client_id: "survey_data_aggregator_client",
//   redirect_uri: "http://localhost:3000",
//   response_type: "code",
//   response_mode: "query",
//   scope: "openid profile survey_aggregator_api",
//   // post_logout_redirect_uri: "https://akredytacja.icpen.pl",
//   post_logout_redirect_uri: "http://localhost:3000",
// };



// export default {
//   authority: "https://demo.documind.co:44350/",
//   client_id: "documind_react_client",
//   redirect_uri: "http://localhost:3001",
//   response_type: "code",
//   response_mode: "query",
//   scope: "openid profile documind_react_client_bff",
//   post_logout_redirect_uri: "http://localhost:3001",
// };
