import React, { useState } from "react";
import SwiperCore, { Navigation, Pagination, Thumbs,EffectFade } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "./Carousel.css";

SwiperCore.use([Navigation, Pagination, Thumbs]);

const Carousel = ({ signatures }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <Swiper
      id="main"
      thumbs={{ swiper: thumbsSwiper }}
      tag="div"
      wrapperTag="ul"
      navigation
      // pagination
      spaceBetween={0}
      slidesPerView={1}
      
      onInit={(swiper) => console.log("Swiper initialized!", swiper)}
      onSlideChanges={(swiper) => {
        console.log("Slide index changed to:", swiper.activeIndex);
      }}
      onReachEnd={() => console.log("Swiper end reached")}
       
    >
      {signatures.map((link) => {
        return (
          <SwiperSlide
            key={link}
            tag="li"
            style={{
              display: "flex",
              // justifyContent: "center",
              listStyle: "none",
              width: "100%",
              // height: "200px",
            }}
          >
            <img src={link} alt="signature image" style={{ width: "100%" }} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
export default Carousel;
